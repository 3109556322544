<template>
  <div class="mask" v-if="visible">
    <customer-detail :visible.sync="visible" :customer="customer">
      <template #header>
        <div class="header">
          <i class="iconfont icon-guanbi" @click="close"></i>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <a-button type="primary" @click="handleOk">{{ $t("ok") }}</a-button>
          <a-button @click="handleCancel">{{ $t("cancel") }}</a-button>
        </div>
      </template>
    </customer-detail>
  </div>
</template>

<script>
import CustomerDetail from "../../customer-detail/index.vue";
export default {
  components: { CustomerDetail },
  data() {
    return {};
  },
  methods: {
    handleOk() {
      this.$emit("ok", this.customer);
      this.close();
    },
    handleCancel() {
      this.close();
    },
    close() {
      this.$emit("update:visible", false);
    },
  },
  mounted() {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default: () => ({
        id: 0,
        last_name: "",
        first_name: "",
        enterprise: "",
        address: "",
        street: "",
        street2: "",
        city: "",
        country: "",
        tel: "",
        user_mobile: "",
        user_email: "",
        head_img: "",
        site: "",
        tax: "",
        tag: "",
        province: "",
        vat_number: "",
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  ::v-deep .customer-detail {
    width: 70%;
  }
  .header {
    display: flex;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    height: 66px;
  }
  .footer {
    display: flex;
    align-items: center;
    height: 96px;
    border-top: 1px solid #ccc;
    .ant-btn {
      margin-right: 16px;
    }
  }
}
.iconfont:hover {
  cursor: pointer;
  color: var(--primaryColor);
}
</style>
