<template>
  <div class="mask" v-if="visible">
    <div class="email-dialog">
      <a-spin :spinning="loading">
        <div class="email-action">
          <i class="iconfont icon-guanbi" @click="handleClose"></i>
        </div>
        <div class="email-body">
          <a-form
            layout="vertical"
            :label-col="{ span: 2 }"
            :wrapper-col="{ span: 22 }"
          >
            <a-form-item :label="$t('to')">
              <div class="relative">
                <a-input
                  v-model="inputValue"
                  @change.stop="handleToChange"
                  @pressEnter.stop="handleToEnter"
                ></a-input>
                <div class="tags">
                  <a-tag
                    v-for="item in form.emails"
                    :key="item.id"
                    closable
                    @close="handleCloseTag(item)"
                  >
                    {{ item.name }} {{ item.email }}
                  </a-tag>
                </div>
                <div
                  class="selection"
                  v-show="showUsers && filterUser.length"
                  v-click-outside.stop="() => (showUsers = false)"
                >
                  <div class="list">
                    <div
                      class="item"
                      :class="user.selected ? 'selected' : null"
                      v-for="user in filterUser"
                      :key="user.id"
                      @click="handleSelect(user)"
                    >
                      <span>
                        <span class="name"> {{ user.name }}</span>
                        <span class="email"> {{ user.email }}</span>
                      </span>
                      <i class="el-icon-check" v-if="user.selected"></i>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item :label="$t('subject')">
              <a-input
                placeholder="subject"
                v-model="form.title"
                @pressEnter="noop"
              />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-textarea
                v-model="form.content"
                placeholder="content"
                :auto-size="{ minRows: 6, maxRows: 20 }"
              />
            </a-form-item>
          </a-form>
          <div class="attachment-wrap">
            <div class="attachment-list">
              <div class="attachment" v-if="form.fileName">
                <div class="flex align-center">
                  <img class="pdficon" :src="pdfIcon" alt="" />
                  <span class="filename">{{ form.fileName }}</span>
                </div>
                <i class="iconfont icon-duigou_kuai"></i>
              </div>
            </div>
            <div class="add-attachment">
              <label for="pdfFile">
                <i class="iconfont icon-fujian" @click="handleAddAttachment">
                  {{ $t("addAttachment") }}
                </i>
                <input
                  type="file"
                  style="opacity: 0"
                  accept=".pdf"
                  ref="fileRef"
                  id="pdfFile"
                  @change="handleFileChange"
                />
              </label>
            </div>
          </div>
        </div>
        <div class="email-footer">
          <a-button type="primary" @click="handleOk">{{ $t("send") }}</a-button>
          <a-button @click="handleClose">{{ $t("cancel") }}</a-button>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import pdfIcon from "@/assets/image/pdf.png";
import api from "@/api";
const debounce = (fn, delay = 500) => {
  let timer = "";
  return function () {
    if (timer) clearInterval(timer);
    timer = setTimeout(fn, delay);
  };
};
export default {
  data() {
    this.lastFetchId = 0;
    // this.fetchUser = debounce(this.fetchUser, 800);
    return {
      pdfIcon,
      data: [],
      value: [],
      inputValue: "",
      loading: false,
      fetching: false,
      showUsers: false,
      users: [],
      form: {
        title: "",
        content: "",
        fileName: "",
        fileUrl: "",
        emails: [],
      },
      file: null,
      list: [],
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    inputValue(val) {
      if (!val) {
        this.showUsers = false;
      }
    },
  },
  computed: {
    filterUser() {
      return this.inputValue
        ? this.users.filter(
            (u) =>
              u.name.includes(this.inputValue) ||
              u.email.includes(this.inputValue)
          )
        : this.users;
    },
  },
  methods: {
    noop(e) {
      console.log("noop", e);
      if (e) {
        e.stopPropagation();
      }
    },
    handleSelect(user) {
      let selected = !user.selected;
      let idx = this.users.findIndex((u) => u.id == user.id);
      this.users.splice(idx, 1, {
        ...user,
        selected,
      });
      if (this.form.emails.find((u) => u.email == user.email)) {
        return;
      }
      this.form.emails.push(this.users[idx]);
      this.inputValue = "";
      this.showUsers = false;
    },
    handleToChange(e) {
      this.fetchUser(this.inputValue);
    },
    handleCloseTag(item) {},
    handleToEnter(e) {
      console.log("handleToEnter", e);
      e.stopPropagation();
      if (!this.inputValue) {
        this.showUsers = false;
        return;
      }
      const reg =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (!reg.test(this.inputValue)) {
        return;
      }
      if (this.form.emails.includes(this.inputValue)) {
        return;
      }
      this.form.emails.push({
        email: this.inputValue,
        id: Date.now(),
      });
      this.inputValue = "";
    },
    handleEnter(e) {
      // console.log(this.value, e);
    },
    async fetchUser(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.data = [];
      this.fetching = true;
      // let { data } = await api.getOwnerCustomerList();
      let { data } = await api.getUserByNameList({ username: value });
      if (fetchId !== this.lastFetchId) {
        return;
      }
      this.users = data.map((c) => ({
        id: c.id,
        email: c.user_email || "",
        name: `${c.last_name || ""} ${c.first_name || ""}`,
        selected: false,
      }));
      this.fetching = false;
      this.showUsers = true;
    },

    async handleFileChange(e) {
      const f = e.target.files[0];
      this.form.fileName = f.name;
      this.file = f;
      // uploadFile([f], "pdf", (f) => {
      //   this.form.fileUrl = f[0];
      // });
    },
    handleAddAttachment() {},
    handleClose() {
      this.init();
      this.$emit("update:visible", false);
    },
    async handleOk() {
      const { title, content } = this.form;
      this.loading = true;
      const formData = new FormData();
      if (!this.file) {
        return;
      }
      formData.append("file", this.file);
      const { file } = await api.saveEmailFile(formData);
      const emails = this.form.emails.map((e) => e.email);
      await api.sendRecommendFormByEmailAccount({
        file,
        title,
        body: content,
        account_arr: emails,
      });
      this.loading = false;
      this.$emit("update:visible", false);
      this.init();
    },
    init() {
      this.form = {
        title: "",
        content: "",
        account_arr: [],
      };
      this.file = null;
    },
  },
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.email-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 1100px;
  // height: 731px;
  background-color: #fff;

  .email-action {
    height: 64px;
    line-height: 64px;
    text-align: right;
    padding: 0 16px;
    border-bottom: 1px solid #ccc;
  }
  .email-body {
    width: 100%;
    height: 470px;
    padding: 0 20px;
    padding-top: 50px;

    .tags {
      margin-top: 8px;
      .ant-tag {
        margin-bottom: 4px;
        width: inherit;
      }
    }
    .selection {
      width: 100%;
      max-height: 240px;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      position: absolute;
      background: #fff;
      border: 1px solid #ccc;
      z-index: 2;
      .list {
        height: 100%;
        overflow: auto;
      }
      .item {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          margin-right: 8px;
        }
        &:hover {
          background-color: #ddd;
        }
        + .selected {
          background-color: #eee;
        }
      }
    }
    .attachment-wrap {
      .attachment-list {
        margin-bottom: 14px;
        .attachment {
          width: 412px;
          height: 70px;
          padding: 0 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #f2f2f2;
          font-size: 16px;
          .filename {
            display: inline-block;
            overflow: hidden;
            width: 300px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .pdficon {
            width: 40px;
            margin: 8px;
          }
          .icon-duigou_kuai {
            color: var(--primaryColor);
          }
        }
      }
    }
    .add-attachment {
      font-size: 18px;
      color: var(--primaryColor);
      &:hover {
        cursor: pointer;
      }
    }

    .ant-input {
      resize: none;
    }
    /deep/ .ant-form {
      .ant-row {
        .ant-form-item-label {
          font-weight: 700;
          label {
            color: #000;
            font-weight: 700;
          }
        }
      }
    }
  }
  .email-footer {
    width: 100%;
    height: 92px;
    line-height: 92px;
    padding: 0 24px;
    border-top: 1px solid #ccc;
    .ant-btn {
      margin-right: 16px;
    }
  }
  .icon-guanbi {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
