<template>
  <div class="recommend" v-loading="loading">
    <div class="flex flex-col shadow">
      <div class="breadcrumb">
        <slot>
          <span>{{ $t("recommend") }}</span></slot
        >
        <div class="btn-group">
          <slot name="btn-group" :export="handleExport">
            <div class="template mr-42">
              <span class="text-gray">{{ $t("template") }}：</span>
              <img
                class="mr-4"
                :src="iconTemplate2"
                @click="handleNewPageOk(2)"
              />
              <img :src="iconTemplate4" @click="handleNewPageOk(4)" />
            </div>
            <template v-if="rid">
              <button
                class="btn font-size-14"
                @click="handleCopy"
                :disabled="id <= 0"
              >
                <i class="iconfont el-icon-document-copy"></i>
                {{ $t("copy") }}
              </button>
              <button class="btn font-size-14" @click="handleShowRecord">
                <i class="iconfont el-icon-document"></i>
                {{ $t("record") }}
              </button>
              <Record :visible.sync="showRecord" :id="rid" />
              <button class="btn font-size-14" @click="handleShare">
                <i class="iconfont el-icon-share"></i>
                {{ $t("share") }}
              </button>
            </template>
            <button class="btn font-size-14" @click="handleExport">
              <i class="iconfont icon-huixingzhen"></i>
              {{ $t("export") }}
            </button>
          </slot>
        </div>
      </div>
      <div class="tool-bar" v-if="toolBarVisible">
        <div class="btn-group">
          <button
            class="btn primary relation font-size-14"
            @click="customerDialogVisible = true"
          >
            {{ $t("recommend") }}
            <customer-dialog
              @select="handleCustomerSelect"
              :visible.sync="customerDialogVisible"
            ></customer-dialog>
          </button>
          <button
            class="btn send-email font-size-14"
            @click="emailDialogVisible = true"
          >
            {{ $t("sendByEmail") }}
          </button>
        </div>
      </div>
    </div>
    <div class="content" ref="contentRef">
      <div class="container" ref="containerRef">
        <product-dialog
          :defaultValue="defaultValue"
          :visible="showProductList && userRole == 'company'"
          :canModify="canModify"
          @ok="handleAdd"
        />
        <div class="scroll-container">
          <div class="scroll-wrap" ref="listWrapRef">
            <div class="template-container" ref="templateRef">
              <div
                class="recommend-item"
                v-for="(p, idx) in recommend"
                :key="p.id"
                @click="handleWrap(idx)"
              >
                <default-template
                  :title="p.title"
                  :column="p.column"
                  :subheadlines="p.subheadlines"
                  @click="handleClick"
                  @change="(e) => handleChange(idx, e)"
                  :products="p.list"
                  :edit="mEdit"
                  :author="username"
                  :canModify="canModify"
                ></default-template>
              </div>
            </div>
          </div>
        </div>
        <div
          class="pages-wrap"
          ref="pageIndicatorRef"
          :class="expanded ? 'expanded' : null"
        >
          <i
            class="el-icon-d-arrow-left expanded-icon"
            @click="handleExpand"
            v-if="expanded"
          />
          <i
            class="el-icon-d-arrow-right expanded-icon"
            @click="handleExpand"
            v-else
          />

          <div class="pages" ref="pageListRef" v-show="!expanded">
            <draggable
              :list="recommend"
              group="pages-indicator"
              tag="ul"
              class="page-list"
              @start="drag = true"
              @end="drag = false"
            >
              <li
                class="page-item-wrap"
                v-for="(p, idx) in recommend"
                :key="p.id"
                @click="handlePageActive(idx)"
              >
                <div class="page-number">{{ idx + 1 }}</div>
                <div
                  class="page-item"
                  :class="pageActive == idx ? 'pageActive' : null"
                >
                  <div class="mask" v-if="canModify">
                    <div class="icon-grop">
                      <i
                        v-if="edit"
                        class="iconfont el-icon-delete"
                        @click.stop="handleDeletePage(idx)"
                      ></i>
                      <i
                        class="iconfont el-icon-plus"
                        @click.stop="addPage(2)"
                      />
                    </div>
                  </div>
                  <default-template
                    :disabled="true"
                    :title="p.title"
                    :column="p.column"
                    :subheadlines="p.subheadlines"
                    @click="handleClick"
                    @change="(e) => handleChange(idx, e)"
                    :products="p.list"
                    :edit="false"
                    :author="author"
                  ></default-template>
                </div>
              </li>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <email-dialog :visible.sync="emailDialogVisible" />
    <confirm
      :visible.sync="customerDetailVisible"
      @ok="handleOk"
      :customer="customer"
    />
    <share-dialog :visible.sync="shareDialogVisible" :id="shareId" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import draggable from "vuedraggable";
import CustomerDialog from "./dialog/CustomerDialog.vue";
import DefaultTemplate from "./template/DefaultTemplate.vue";
import ProductDialog from "./dialog/ProductDialog.vue";
import EmailDialog from "./dialog/EmailDialog.vue";
import Confirm from "./dialog/confirm.vue";
import api from "@/api";
import { Canvas2Image } from "./index";
import Record from "./dialog/Record.vue";
import iconTemplate2 from "@/assets/image/icon-template-2.png";
import iconTemplate4 from "@/assets/image/icon-template-4.png";
import ShareDialog from "./dialog/ShareDialog.vue";

const { mapState } = createNamespacedHelpers("jiyang/user");
const { mapState: mapStateRecommend, mapMutations } =
  createNamespacedHelpers("jiyang/recommend");

let cache = null;

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    breadcrumbVisible: {
      type: Boolean,
      default: true,
    },
    toolBarVisible: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    rid: {
      type: [Number, String],
      default: 0,
    },
    canModify: {
      type: Boolean,
      default: true,
    },
    showProductList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      iconTemplate2,
      iconTemplate4,
      loading: false,
      showRecord: false,
      prodDialogVisible: false,
      emailDialogVisible: false,
      customerDialogVisible: false,
      customerDetailVisible: false,
      shareDialogVisible: false,
      customer: { id: 0 },
      id: 0,
      idx: 0,
      idx2: 0,
      pageActive: 0,
      offset: 0,
      defaultValue: 0,
      mEdit: this.edit,
      author: this.username,
      width: "100%",
      hadRecommend: false,
      shareId: this.rid,
      expanded: false,
      hadEdited: false,
    };
  },
  created() {
    this.shareId = this.rid;
    this.customer.id = this.rid;
  },
  mounted() {
    this.init();
    this.$refs.templateRef.addEventListener("wheel", this.scrollToPageDown);
    document.body.addEventListener("keypress", this.handlePressEnter);
  },
  beforeDestroy() {
    if (!this.hadRecommend) {
      this.handleSave();
    }
    document.body.removeEventListener("keypress", this.handlePressEnter);
    cache = null;
  },
  watch: {
    visible(newVal) {
      this.visible = newVal;
    },
    rid(val) {
      this.shareId = val;
      this.customer.id = val;
    },
    recommend: {
      deep: true,
      handler(val) {
        if (cache == null) {
          cache = JSON.stringify(val);
        }
      },
    },
  },
  computed: {
    ...mapState(["info","userRole"]),
    ...mapStateRecommend(["recommend"]),
    username() {
      return `${this.info.last_name}${this.info.first_name}`;
    },
    disabled() {
      return !this.id;
    },
    assertSmallScreen() {
      return window.innerWidth <= 1440;
    },
  },
  methods: {
    ...mapMutations([
      "addPage",
      "removePage",
      "initRecommend",
      "setCurrentPage",
      "editRecommend",
      "clearRecommend",
      "resetHolder",
      "hideHolder",
    ]),

    handleExpand() {
      this.expanded = !this.expanded;
    },
    handleShare() {
      if (this.shareId) {
        this.shareDialogVisible = true;
      }
    },
    handleShowRecord() {
      this.showRecord = true;
    },
    handlePressEnter(e) {
      if (e.target != document.body) {
        return;
      }
      if (e.key == "Enter") {
        this.addPage(2);
      }
    },
    scrollToPageDown(e) {
      const deltaY = e.deltaY;
      const len = this.recommend.length - 1;
      if (deltaY > 0) {
        let page = ++this.pageActive;
        page >= len && this.$amessage.info("已经是最后一页");
        this.pageActive = page >= len ? len : this.pageActive;
      } else {
        let page = --this.pageActive;
        page <= 0 && this.$amessage.info("已经是第一页");
        this.pageActive = page <= 0 ? 0 : this.pageActive;
      }
      this.setCurrentPage(this.pageActive);
      this.$nextTick(() => {
        const templates = document.getElementsByClassName("recommend-item");
        templates[this.pageActive].scrollIntoView(false);
      });
    },
    setCache(values) {
      window.localStorage.setItem(
        `recommend-${this.rid}`,
        JSON.stringify(values)
      );
    },
    getCache() {
      return JSON.parse(window.localStorage.getItem(`recommend-${this.rid}`));
    },
    clearCache() {
      window.localStorage.removeItem(`recommend-${this.rid}`);
    },
    handleDeletePage(idx) {
      this.removePage(idx);
    },
    handleOk(c) {
      if (!this.validate()) {
        return;
      }
      this.hadRecommend = true;
      this.customer = c;
      const { user_id } = this.customer;
      this.useRequest(api.saveAndSendRecommendForm, {
        user_id,
        customer_id_arr: [c.user_id],
        pages: this.recommend,
      });
      this.ok();
    },
    init() {
      if (this.rid) {
        this.id = this.rid;
        this.getById();
      } else {
        this.clearRecommend();
      }
    },
    handlePrevious() {
      if (this.pageActive == 0) {
        return;
      }
      this.scroll();
      this.pageActive--;
    },
    handlePageActive(idx) {
      this.pageActive = idx;
      this.setCurrentPage(this.pageActive);
      this.scrollY();
    },
    handleNext() {
      if (this.pageActive >= this.recommend.length - 1) {
        return;
      }
      this.scroll(-1);
      this.pageActive++;
    },
    scrollY() {
      this.$nextTick(() => {
        const pages = document.getElementsByClassName("recommend-item");
        pages[this.pageActive].scrollIntoView();
      });
    },
    async getById() {
      this.loading = true;
      const res = await api.getRecommendFormInfo(this.id);
      this.customer.id = res.customer_id;
      this.initRecommend(res.pages);
      this.author = res.author;
      this.loading = false;
    },
    async handleCustomerSelect(c) {
      this.customer = c;
      this.customerDetailVisible = true;
    },
    async handleCopy() {
      if (!this.id) {
        return;
      }
      console.log(this.id);
      await api.copyRecommendForm({ id: this.id });
      let msg = this.$t("copyok");
      this.$message(msg);
    },
    validate() {
      return this.recommend.some((p) => p.list.some((pro) => !pro.h));
    },
    handleSave() {
      if (!this.validate()) {
        return;
      }
      const { user_id } = this.customer;
      if (cache == JSON.stringify(this.recommend)) {
        return;
      }
      if (this.id && user_id) {
        this.useRequest(api.updateRecommendForm, {
          customer_id: user_id,
          pages: this.recommend,
          id: this.id,
          author: this.username,
        });
      } else {
        this.useRequest(api.saveRecommendForm, {
          pages: this.recommend,
          author: this.username,
        });
      }
      this.clearCache();
    },
    ok() {
      this.$emit("ok", false);
      this.hidden();
    },
    close() {
      this.$emit("close", false);
      this.hidden();
    },
    hidden() {
      this.$emit("update:visible", false);
    },
    handleNewPageOk(col) {
      this.addPage(col);
    },

    handleAdd(products) {},
    async useRequest(api, data) {
      this.loading = true;
      await api(data);
      this.loading = false;
    },
    handleWrap(idx) {
      this.idx = idx;
    },
    handleClick(idx) {
      this.prodDialogVisible = true;
      this.idx2 = idx;
    },
    handleChange(idx, form) {
      this.editRecommend({ idx, form });
    },
    async handleDelete() {
      await api.deleteRecommendForm({ id: this.id });
      this.close();
    },
    handleExport() {
      this.mEdit = false;
      this.hideHolder();
      this.$nextTick(async () => {
        const pages = document.querySelectorAll(".recommend-item");
        if (!pages) {
          return;
        }
        const pageWidth = pages[0].clientWidth;
        const pageHeight = pages[0].clientHeight;
        let scale = 2;
        const doc = new jsPDF({
          orientation: "l",
          unit: "px",
          format: [pageWidth * scale, pageHeight * scale],
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        });
        this.loading = true;
        const size = pages.length;
        for (let i = 0; i < size; i++) {
          let canvas = document.createElement("canvas");
          canvas.width = pageWidth * scale;
          canvas.height = pageHeight * scale;
          await html2canvas(pages[i], {
            useCORS: true,
            allowTaint: false,
            scale: scale,
            canvas,
            width: pageWidth,
            height: pageHeight,
            backgroundColor: "#fff",
          });
          const img = Canvas2Image.convertToJPEG(
            canvas,
            pageWidth * scale,
            pageHeight * scale
          );
          doc.addImage(
            img,
            "JPEG",
            0,
            0,
            pageWidth * scale,
            pageHeight * scale
          );
          if (i !== size - 1) {
            doc.addPage([pageWidth * scale, pageHeight * scale]);
          }
        }
        doc.save(`recommend.pdf`);
        this.resetHolder();
        this.loading = false;
        this.mEdit = true;
      });
    },
  },
  components: {
    DefaultTemplate,
    ProductDialog,
    EmailDialog,
    CustomerDialog,
    Confirm,
    draggable,
    Record,
    ShareDialog,
  },
};
</script>
<style lang="scss" scoped>
.recommend {
  overflow: hidden;
  height: 100%;
  .tool-bar {
    background-color: #fff;
    margin-top: -2px;
  }
  .shadow {
    box-shadow: 0px 0px 13px 0px rgba(177, 177, 177, 0.3);
  }
  .btn-group {
    display: flex;
    align-items: center;
    position: relative;
    .btn {
      height: 3rem;
      line-height: 3rem;
      color: #959595;
      background-color: transparent;
      padding: 0 8px;
      border: none;
      border-radius: 2px;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 22px;
      .iconfont {
        font-size: 16px;
        margin-right: 8px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .btn.primary {
      background-color: var(--primaryColor);
      color: #fff;
      margin-right: 21px;
    }
  }
  .breadcrumb {
    display: flex;
    justify-content: space-between;
    color: var(--primaryColor);
    height: $head-line-height;
    line-height: $head-line-height;
    padding-left: 32px;
    padding-right: 32px;
    font-size: $head-font-size;
    font-weight: 700;
    background-color: #fff;
    border-bottom: 1px solid #d6d6d6;
    align-items: center;
    > span {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }
  .content {
    overflow: hidden;
    width: 100%;
    height: 100%;
    .tool-bar {
      display: flex;
      align-items: center;
      padding: 2.4rem 3rem;
      .btn-group {
        display: flex;
        align-items: center;
        .send-email {
          background-color: #eaeaea !important;
          color: var(--primaryColor) !important;
        }
        .btn {
          height: 3rem;
          line-height: 3rem;
          width: 11.2rem;
          color: #959595;
          background-color: transparent;
          padding: 0;
          border: none;
          border-radius: 2px;
          font-size: 1.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconfont {
            font-size: 1.4rem;
            margin-right: 1rem;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .btn.primary {
          background-color: var(--primaryColor);
          color: #fff;
          margin-right: 2rem;
        }
      }
    }

    .container {
      height: var(--containerHeight);
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: auto;
    }
    .scroll-container {
      padding: 14px 0px;
      height: 100%;
	  margin: auto;
    }
    .scroll-wrap {
      height: 100%;
      overflow: hidden;
    }
    .template-container {
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 0px 4px;
      align-items: center;
      gap: 16px;
      .recommend-item {
        background-color: #fff;
      }
    }
  }
  .pages-wrap {
    position: relative;
    overflow: hidden;
    width: 241px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    flex-shrink: 0;
    box-shadow: -3px 10px 8px 0px rgb(177 177 177 / 30%);
    .expanded-icon {
      cursor: pointer;
      position: absolute;
      font-size: 30px;
      top: 50%;
      left: 0px;
      transform: translate(0, -50%);
      color: #f34d4d;
    }
    .iconfont {
      font-size: 20px;
      cursor: pointer;
      position: relative;
      z-index: 10;
    }
    .pages {
      overflow-y: auto;
      overflow-x: hidden;
    }
    .page-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;
      padding: 14px 0;
      .page-item-wrap {
        display: flex;
        .page-number {
          width: 46.7px;
          text-align: center;
          font-size: 20px;
          color: #626262;
        }
      }
      .page-item {
        width: 171px;
        height: 122px;
        border: 1px solid #515151;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 13px;
        ::v-deep .recommend-template {
          transform: scale(0.15);
        }
      }

      .pageActive {
        border: 1px solid var(--primaryColor);
      }
    }
  }
}
.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(236, 236, 236, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-grop {
    :nth-child(1) {
      margin-right: 20px;
    }
  }
}
.expanded {
  width: 30px !important;
}
.border {
  background: #eaeaea;
  border: 1px solid #b5b5b5 !important;
}
.relation {
  position: relative;
}

@media screen and (max-width: 1440px) {
  .recommend .pages-wrap {
    width: 178px;
  }
  .recommend .content .scroll-container {
    padding-top: 24px;
    height: 100%;
  }
  .recommend .pages-wrap .page-list .page-item-wrap .page-number {
    width: 34.7px;
  }
  .recommend .pages-wrap .page-list .page-item {
    width: 125px;
    height: 100px;
  }
}
</style>
