<template>
  <div class="mask" v-if="visible">
    <div class="dialog">
      <div class="header">
        <i class="iconfont icon-guanbi pointer" @click="handleClose"></i>
      </div>
      <div class="body">
        <div class="share">
          <span class="text">{{ $t("shareTo") }}</span>
          <div class="urls">
            <div class="box-wrap" :data-clipboard-text="url">
              <div class="icon-wrap">
                <img class="icon" :src="linkIcon" alt="" />
              </div>
              <span class="share-text pointer">{{ $t("copylink") }}</span>
            </div>
          </div>
        </div>
        <div class="line">
          <div></div>
        </div>
        <div class="qr-wrap">
          <div class="qr-code">
            <img :src="qrImage" alt="" />
          </div>
          <div>
            <p>{{ $t("scanToWatch") }}</p>
            <p>{{ $t("clickTosave") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
import QRCode from "qrcode";

import linkIcon from "@/assets/image/link.png";
export default {
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    id: {
      default: "",
      type: [String, Number],
    },
  },
  data() {
    return {
      linkIcon: linkIcon,
      qrImage: "",
    };
  },
  computed: {
    url() {
      const { protocol, host } = window.location;
      return `${protocol}//${host}/#/recommend-preview/${this.id}`;
    },
  },
  mounted() {
    new ClipboardJS(".box-wrap");
    document.body.appendChild(this.$el);
    this.generateQR(this.url);
  },
  beforeDestroy() {
    const el = document.body.hasChildNodes(this.$el);
    if (el) {
      document.body.removeChild(this.$el);
    }
  },
  methods: {
    async generateQR(text) {
      try {
        this.qrImage = await QRCode.toDataURL(text);
        console.log(this.qrImage);
      } catch (err) {
        console.error(err);
      }
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(179, 179, 179, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.dialog {
  width: 775px;
  height: 733px;
  background-color: #fff;
}
.body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.share {
  display: flex;
  margin-top: 65px;
  font-size: 18px;
  margin-left: 146px;
  align-items: center;
  margin-bottom: 70px;
}
.share-text {
  font-size: 16px;
}
.line {
  width: 100%;
  height: 1px;
  div {
    margin: 0 auto;
    width: 670px;
    height: 1px;
    background-color: #cccfd4;
  }
}
.text {
  margin-right: 50px;
}
.qr-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  margin-top: 95px;
  align-items: center;
  div {
    margin-top: 16px;
    text-align: center;
    p {
      margin-bottom: 0em;
    }
  }
}
.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
}
.icon-wrap {
  width: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #b7b7b7;
  margin-bottom: 4px;
}
.icon {
  width: 48px;
  height: 48px;
}
.header {
  display: flex;
  height: 64px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #cccfd4;
}
</style>