<template>
  <div
    v-if="visible"
    class="product-dialog"
    :style="{ top: y + 'px', left: x + 'px' }"
    ref="dialogRef"
    @mousedown="handleDown"
    @mouseup="handleUp"
  >
    <div class="action"></div>
    <a-spin :spinning="loading"> </a-spin>
    <div class="header">
      <a-select
        :default-value="$t('fromLib')"
        style="width: 139px"
        @change="handleChange"
      >
        <a-select-option value="value"> {{ $t("fromLib") }}</a-select-option>
      </a-select>
      <a-select
        :default-value="$t('filterGoods')"
        style="width: 78px"
        @change="handleChange"
      >
        <a-select-option value="value">
          {{ $t("filterGoods") }}
        </a-select-option>
      </a-select>
      <a-input @pressEnter="handleEnter" v-model="search">
        <a-icon slot="suffix" type="search" @click="handleEnter" />
      </a-input>
    </div>
    <div class="product-list-wrap" ref="listWrapRef" @scroll="load">
      <div class="product-list" ref="listRef">
        <div class="product-card" v-for="prod in products" :key="prod.id">
          <div class="product-header">
            <span class="catalog">
              {{
                prod.type == 1
                  ? $t("design")
                  : prod.type == 2
                  ? $t("threeD")
                  : ""
              }}
            </span>
          </div>
          <div class="product-body">
            <div class="img-wrap">
              <img class="img" :src="prod.img" alt="" crossOrigin="Anonymous" />
            </div>
          </div>
          <div class="prod-footer">
            <div class="prod-title">{{ prod.name }}</div>
            <div class="prod-desc">{{ prod.desc }}</div>
            <div class="prod-price">{{ prod.price }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button
        type="primary"
        @click="handleSubmit"
        style="width: 120px"
        v-if="canModify"
      >
        {{ $t("fromLocal") }}
      </a-button>
      <input
        class="fileRef"
        type="file"
        ref="fileRef"
        @input="handleFileInput"
        multiple
      />
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations } = createNamespacedHelpers("jiyang/recommend");
import upLoadFile from "@/utils/upload_to_cos.js";
let dragItem = null;
let currentItem = null;
let originItem = null;
export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    canModify: {
      type: Boolean,
      default: true,
    },
    top: {
      type: Number,
      default: 100,
    },
    left: {
      type: Number,
      default: 80,
    },
    defaultValue: {
      type: Number || String,
      default: 0,
    },
  },
  data() {
    return {
      x: this.left,
      y: this.top,
      products: [],
      search: "",
      loading: false,
      selected: "",
      selectedProd: [],
      pageSize: 0,
      oldIndex: 0,
      targetIndex: 0,
      options: {
        group: {
          name: "products",
          put: false,
          pull: "clone",
        },
        handle: ".product-card",
        draggable: ".product-card",
        chosenClass: "chosenClass-product-item",
        dragoverBubble: false,
        ghostClass: "recommend-template__content",
        removeCloneOnHide: true,
        sort: false,

        onClone: (evt) => {
          dragItem = evt.item;
          const oldIndex = evt.oldIndex;
          currentItem = this.products[oldIndex];
          originItem = evt.item.innerHTML;
        },
        onEnd: (evt) => {
          if (evt.from == evt.to) {
            return;
          }

          if (evt.from == evt.to) {
            return;
          }
          const { oldIndex, newIndex } = evt;
          evt.item.parentElement.removeChild(evt.item);
          const item = this.products[oldIndex];
          this.addItem({
            index: newIndex,
            item: { ...item },
            fn: () => {
              this.$amessage.error(this.$t("norepeat"));
            },
          });
        },
      },
    };
  },
  computed: {
    ...mapState(["recommend", "recommendPage"]),
  },
  created() {
    this.getProductList();
  },
  mounted() {
    this.load();
    new Sortable(this.$refs.listRef, this.options);
  },
  watch: {
    search(newVal) {
      if (!newVal.trim()) {
        this.getProductList();
      }
    },
  },
  methods: {
    ...mapMutations([
      "addPage",
      "replaceHolder",
      "hideHolder",
      "addItem",
      "addProductFromLocal",
    ]),

    handleFileInput(e) {
      const files = Array.from(e.target.files);
      upLoadFile(files, "images", (urls) => {
        this.addProductFromLocal(urls);
      });
    },
    async getProductList() {
      this.loading = true;
      try {
        const { data = [] } = await this.$api.getProductList({
          page_size: this.pageSize,
        });
        this.products = this.translate(data);
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },

    async getData(e) {
      if (!e) {
        return;
      }
      let scrollHeight = e.target.scrollHeight;
      let clientHeight = e.target.clientHeight;
      let scrollTop = e.target.scrollTop;
      if (clientHeight + scrollTop >= scrollHeight) {
        this.pageSize += 10;
        this.getProductList();
      }
    },
    async handleEnter() {
      this.loading = true;
      const { data = [] } = await this.$api.getProductList({
        page_size: 10,
        search: { name: this.search },
      });
      this.products = this.translate(data);
      this.loading = false;
    },
    translate(data) {
      return data.map((d) => {
        let { id, name = "", desc = [], price = "", file_info, type = 1 } = d;
        let img =
          (file_info && file_info.main_file && file_info.main_file.img_url) ||
          "";

        desc = (desc && desc.join()) || "";
        return {
          type,
          id,
          desc,
          price,
          img,
          name,
        };
      });
    },
    load(e) {
      this.getData(e);
    },
    handleSubmit() {
      this.$refs.fileRef.click();
    },
    handleProdChange(prod) {
      this.selectedProd = [prod];
    },
    handleChange() {},
    close() {
      this.$emit("update:visible", false);
    },
    handleDown(e) {
      const { offsetLeft, offsetTop } = this.$refs.dialogRef;
      const { clientX, clientY } = e;
      let left = clientX - offsetLeft;
      let top = clientY - offsetTop;
      document.onmousemove = (e) => {
        const { clientX, clientY } = e;
        this.x = clientX - left;
        this.y = clientY - top;
      };
    },
    handleUp() {
      document.onmousemove = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 518px;
  min-width: 200px;
  background: #fbfbfb;
  // box-shadow: 0px 0px 13px 0px rgba(177, 177, 177, 0.3);
  box-shadow: 5px 3px 13px 0px rgb(177 177 177 / 30%);
  flex-shrink: 0;
  .action {
    width: 100%;
    padding: 8px;
    text-align: right;
    margin-bottom: 12px;
    .iconfont:hover {
      cursor: pointer;
    }
  }
  .header {
    width: 100%;
    display: flex;
    padding: 0px 21px;
    margin-bottom: 12px;
    .ant-select {
      margin-right: 10px;
    }
  }
  .ant-spin-container {
    display: flex;
  }
  .ant-spin-spinning {
    display: inline-block;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 245px;
    z-index: 10;
    height: 100px;
  }
  .product-list-wrap {
    // height: 550px;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;
  }
  .product-list {
    width: 100%;
    // height: 550px;
    display: flex;
    justify-content: flex-start;
    gap: 14px;
    row-gap: 11px;
    flex-wrap: wrap;
    padding: 4px 16px;
    margin-bottom: 12px;
    // overflow: auto;
    .product-card {
      width: 233px;
      height: 280px;
      background: #ffffff;
      border: 1px solid #e2dede;
      position: relative;
      margin-bottom: 0;
      &:hover {
        border: 1px solid var(--primaryColor);
      }
      .product-header {
        width: 100%;
        // position: absolute;
        padding: 0 8px 0 16px;
        padding-top: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: right;
        font-size: 12px;
        font-weight: 500;
        color: #323131;
      }
      .product-body {
        width: 100%;
        height: 163px;
        background: #ffffff;
        border-bottom: 1px solid #e2dede;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .img-wrap {
        width: 117px;
        height: 80px;
        overflow: hidden;
        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .prod-footer {
        padding: 21px;
        .prod-title {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 4px;
          word-break: break-all;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .prod-desc {
          font-size: 10px;
          font-weight: 400;
          text-align: left;
          margin-bottom: 4px;
          height: 36px;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
        }
        .prod-price {
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          color: #000000;
        }
      }
    }
  }
  .fileRef {
    position: absolute;
    z-index: -999;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .footer {
    width: 100%;
    height: 6.2rem;
    line-height: 6.2rem;
    padding: 0px 21px;
    text-align: right;
    // border-top: 1px #141414 solid;
    box-shadow: 0px 0px 13px 0px rgba(177, 177, 177, 0.3);
  }
}
@media screen and (max-width: 1440px) {
  .product-dialog {
    width: 329px;
    .header {
      padding: 0 8px;
    }
    .product-list {
      gap: 4px !important;
      padding: 0 8px;
      height: 140px !important;
      .product-card {
        width: 151px !important;
        height: 225px;
        .product-body {
          height: 120px;
        }
        .prod-footer {
          padding: 8px;
          .prod-desc {
            font-size: 10px;
            font-weight: 400;
            text-align: left;
            margin-bottom: 4px;
            height: 25px;
            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
