<template>
  <div
    class="customer-dialog"
    :style="{ top, left }"
    v-if="visible"
    v-click-outside.capture="close"
  >
    <div class="search">
      <a-input
        v-model="inputName"
        placeholder="search"
        @pressEnter="handleSearch"
        @change="handleChange"
      ></a-input>
    </div>
    <div class="create-customer">
      <a-button icon="plus" block @click="handleNewCustomer">
        {{ $t("newCustomer") }}
      </a-button>
    </div>
    <ul class="customer-list">
      <li
        class="customer-item"
        v-for="c in filterCustomers"
        :key="c.id"
        @click.stop="handleClick(c)"
      >
        {{ c.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import api from "@/api";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: "24px",
    },
    left: {
      type: String,
      default: "-37px",
    },
    url: {
      type: String,
      default: "/crm/recommend?menu=recommend&cw=createView",
    },
  },
  data() {
    return {
      inputName: "",
      customers: [],
    };
  },
  created() {},
  watch: {
    visible() {
      if (this.visible) {
        this.getCustomer();
      }
    },
  },
  computed: {
    filterCustomers() {
      const res = this.customers.filter((c) => c.name.includes(this.inputName));
      if (res.length) {
        return res;
      }
      return this.customers;
    },
  },
  methods: {
    handleChange() {},
    async handleSearch() {
      if (this.inputName) {
		 // this.getCustomer();
      } else {
        this.getCustomer();
      }
    },
    async getCustomer() {
      // const { data } = await api.getUserByNameList();
      const  data  = await api.getCustomerSelectList();
      this.customers = data.map((d) => ({
        ...d,
        name: `${d.last_name || ""}${d.first_name || ""}`,
      }));
    },
    handleClick(c) {
      this.$emit("select", c);
      this.close();
    },
    handleNewCustomer() {
      this.$router.push({
        // /crm/recommend?menu=recommend&cw=createView
        path: `/crm/customer?menu=myCustomer&v=createView&url=${this.url}`,
      });
    },
    close() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-dialog {
  position: absolute;
  top: 24px;
  left: -37px;
  width: 300px;
  // height: 251px;
  z-index: 99;
  box-shadow: 0px 0px 13px 0px rgba(177, 177, 177, 0.3);
  background-color: #fff;
  .search {
    width: 100%;
    padding: 12px 14px;
  }
  .create-customer {
    .ant-btn {
      line-height: 1.499;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      text-align: left;
      background-image: none;
      border: 1px solid transparent;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      touch-action: manipulation;
      height: 32px;
      padding: 0 15px;
      font-size: 14px;
      border-radius: 2px;
      color: var(--primaryColor);
      background-color: #fff;
      border-color: transparent;
    }
  }
  .customer-list {
    max-height: 154px;
    overflow: auto;
    .customer-item {
      width: 100%;
      padding: 0 14px;
      font-size: 18px;
      font-weight: 300;
      text-align: left;
      color: #000000;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        background-color: #ddd;
      }
    }
  }
}
</style>
