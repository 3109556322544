<template>
  <div class="recommend-template">
    <div class="title mt" :class="{ dashborder: !form.title && edit }">
      <span class="tip text text-primary" @click="edit = false" v-if="!edit">
        {{ form.title }}
      </span>
      <a-input
        :defaultValue="title"
        :placeholder="$t('clickAddText')"
        v-model="form.title"
        :autoFouce="true"
        :maxLength="48"
        :disabled="!edit"
        v-else
        @blur="edit = true"
      ></a-input>
    </div>
    <div
      class="subheadlines"
      :class="{ dashborder: !form.subheadlines && edit }"
    >
      <div class="subheadlines-text" @click="edit = false" v-if="!edit">
        {{ form.subheadlines }}
      </div>
      <a-textarea
        class="textarea"
        :defaultValue="subheadlines"
        :placeholder="$t('clickAddText')"
        :rows="3"
        :maxLength="180"
        v-else
        :autoFouce="true"
        v-model="form.subheadlines"
        @change="handleSubheadlinesChange"
        @blur="edit = true"
      ></a-textarea>
    </div>
    <div class="recommend-template__content" :class="columnSty" ref="listRef">
      <div
        class="holder"
        :class="{ dashborder: !prod.img && !prod.hide }"
        v-for="(prod, idx) in list"
        :key="prod.id"
      >
        <i
          class="close el-icon-delete"
          v-if="!prod.h && edit"
          @click="handleClickDelete(prod, idx)"
        ></i>
        <div
          class="image"
          :class="prod.img || prod.hide ? null : 'border-bottom'"
          @click="handleClick(idx)"
        >
          <div class="img-wrap">
            <img
              class="default"
              :src="shoeIcon"
              v-show="!prod.img && !prod.hide"
            />
            <img
              class="img"
              :src="prod.img"
              v-show="prod.img"
              crossorigin="anonymous"
            />
          </div>
        </div>
        <div class="footer">
          <a-input
            v-if="edit || prod.name"
            :defaultValue="prod.name"
            :disabled="!edit"
            v-model="prod.name"
            :placeholder="$t('clickAddText')"
          ></a-input>
          <a-input
            v-if="edit || prod.desc"
            :defaultValue="prod.desc"
            :disabled="!edit"
            v-model="prod.desc"
            :placeholder="$t('clickAddText')"
          ></a-input>
          <a-input
            v-if="edit || prod.price"
            :disabled="!edit"
            :defaultValue="prod.price"
            v-model="prod.price"
            :placeholder="$t('clickAddText')"
          ></a-input>
        </div>
      </div>
    </div>

    <div class="recommend-template__footer">
      <div class="line"></div>
      <span class="text text-primary font-size-16 font-700">
        {{ `Recommend From ${author}` }}
      </span>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import { mapMutations } from "vuex";
const dragItemCls = "dragged-product-item";
import shoeIcon from "@/assets/image/shoe-icon.png";
import { gid } from "../index";
export default {
  data() {
    return {
      shoeIcon,
      x: 0,
      y: 0,
      drag: true,
      form: {
        title: this.title,
        subheadlines: this.subheadlines,
        author: this.author,
      },
      showInput: true,
      showAuthor: true,
      showSubheadlines: true,
      showNameInput: true,
      defaulSrc:
        "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.91998848205740491642044805.706.png",
      list: [],
      options: {
        group: {
          name: "products",
          put: this.canModify,
        },
        sort: this.canModify,
        handle: ".holder",
        draggable: ".holder",
        forceFallback: false,
        fallbackClass: dragItemCls,
        fallbackOnBody: false,
        disabled: this.disabled,
      },
      mColumn: 2,
    };
  },
  mounted() {
    new Sortable(this.$refs.listRef, this.options);
  },
  computed: {
    columnSty() {
      return `cloumn-${Math.max(this.list.length, this.mColumn)}`;
    },
  },
  methods: {
    ...mapMutations("jiyang/recommend", ["removeProduct"]),

    handleSubheadlinesChange(e) {
      if (this.form.subheadlines.length >= 180) {
        this.$message({
          message: this.$t("contentMaxLen") + "180",
          type: "warning",
        });
      }
    },
    handleClickDelete(prod, index) {
      this.removeProduct({ index });
    },
    handleTextClick() {
      if (this.canModify) {
        this.edit = true;
      }
    },
    handleClick(idx) {
      if (this.edit) {
        this.$emit("click", idx);
      }
    },
  },
  created() {
    for (let i = 0; i < this.mColumn; i++) {
      this.list.push({
        id: gid(),
        img: "",
        h: true,
      });
    }

    this.list = this.products;
  },
  watch: {
    column(newVal) {
      this.mColumn = newVal;
    },
    form: {
      deep: true,
      handler() {
        this.$emit("change", this.form);
      },
    },
    products: {
      deep: true,
      handler(newVal) {
        this.list = newVal;
      },
    },
  },
  props: {
    subheadlines: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    products: {
      type: Array,
      default: () => [],
    },
    author: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: true,
    },
    canModify: {
      type: Boolean,
      default: true,
    },
    column: {
      type: Number,
      default: 2,
    },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.recommend-template {
  width: 1047px;
  height: 740px;
  padding: 0px 26.7px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 13px 0px rgb(177 177 177 / 30%);
  .tip {
    width: 100%;
    height: 100%;
    font-size: 3.6rem;
  }
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  .border-bottom {
    border-bottom: 1px dashed #e5e5e5;
  }
  .dashborder {
    border: 1px dashed #e5e5e5;
  }
  .mt {
    margin-top: 3.1rem;
  }
  .title {
    width: 100%;
    height: 6.6rem;
    line-height: 6.6rem;
    background: #ffffff;
    margin-bottom: 0.9rem;
    .ant-input {
      height: 100%;
      font-size: 3.6rem;
      line-height: 6.4rem;
      color: var(--primaryColor);
    }
  }
  .subheadlines {
    margin-bottom: 18px;
    font-size: 18px;
    height: 95px;
    line-height: 18px;
    color: #7a7c7f;
    text-align: left;
    .ant-input {
      font-size: 1.8rem;
    }
    .subheadlines-text {
      word-wrap: break-word;
      word-break: normal;
      width: 100%;
    }
  }
  .recommend-template__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    height: 496px;
    .products {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 20px;
    }
  }
  .recommend-template__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 49px;
    .line {
      width: 100%;
      padding: 0 62px;
      height: 2px;
      background-color: var(--primaryColor);
      margin-bottom: 10px;
    }
    .ant-input {
      height: 100%;
      font-size: 1.6rem;
      height: 18px;
      line-height: 18px;
      resize: none;
      text-align: center;
    }
  }
  .holder {
    position: relative;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    &:hover {
      .close {
        opacity: 1;
        display: block;
        transition: all 0.2s linear;
      }
    }
    .close {
      font-size: 22px;
      display: none;
      opacity: 0;
      color: #f14c4c;
      position: absolute;
      top: 10px;
      right: 0px;
      z-index: 99;
    }

    .border-bottom {
      border-bottom: 1px dashed #e5e5e5;
    }
    .image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .img-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 485px;
        .default {
          width: 70px;
        }
        .img {
          display: block;
          margin: auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }
    .footer {
      flex: 1;
      width: 100%;
      // height: 16.4rem;
      padding: 1.6rem 1.2rem;
      .ant-input {
        height: 26px;
        line-height: 26px;
      }
    }
  }
  .ant-input {
    border: 1px dashed transparent;
    margin-bottom: 1px;
    resize: none;
    padding: 0px;
    // height: 24px;
  }
  .ant-input[disabled] {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
  }
  .cloumn-2 {
    .holder {
      .img-wrap {
        height: 288px;
      }
      .footer {
        // height: 131px;
        height: 175px;
      }
    }
  }
  .cloumn-3 {
    .products {
      flex-wrap: nowrap;
      gap: 5px;
    }
    .holder {
      .img-wrap {
        width: 326px;
        height: 320px;
      }
      .footer {
        height: 148px;
      }
    }
  }
  .cloumn-4 {
    .products {
      flex-wrap: nowrap;
      gap: 5px;
    }
    .holder {
      .img-wrap {
        width: 243px;
        height: 320px;
      }
      .footer {
        // height: 148px;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .recommend-template {
    width: 841px !important;
    height: 595px !important;
  }
  .cloumn-3 .holder .img-wrap {
    width: 247px !important;
    height: 177px !important;
  }
  .cloumn-2 .holder .img-wrap {
    height: 200px !important;
    width: 381px !important;
  }
  .recommend-template__content {
    height: 362px !important;
  }
  .cloumn-2 .holder .footer {
    height: 100px !important;
  }
  .cloumn-4 .holder .img-wrap {
    width: 189px !important;
    height: 223px !important;
  }
}
</style>
