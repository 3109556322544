<template>
  <div class="record-wrap" v-if="mVisible">
    <div class="head">
      <span>历史记录</span>
      <i class="el-icon-close pointer" @click="handleClose"></i>
    </div>
    <div class="body">
      <div class="record-list">
        <div class="record-item" v-for="record in records" :key="record.id">
          <img class="avatar" :src="record.avatar" alt="" />
          <div class="detail">
            <div class="nickname-action">
              <span class="nickname">{{ record.nickname }}</span>
              <span class="action">{{ record.action }}</span>
            </div>
            <div class="time">{{ record.time }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
let url =
  "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.174267730247144261640329585.425.png";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      records: [],
      mVisible: this.visible,
    };
  },
  watch: {
    visible(val) {
      this.mVisible = val;
      if (val) {
        this.getRecommendRecordList();
      }
    },
  },
  methods: {
    async getRecommendRecordList() {
      const res = await api.getRecommendRecordList({ id: this.id });
      this.records = res.data.map((item) => {
        const { operat, operat_time, desc = "" } = item;
        return {
          ...item,
          avatar: operat.head_img || url,
          time: operat_time,
          nickname: `${operat.last_name || ""}${operat.first_name || ""}`,
          action: desc,
        };
      });
    },
    handleClose() {
      this.mVisible = false;
      this.$emit("close", false);
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.record-wrap {
  position: absolute;
  top: 48px;
  right: -26px;
  width: 480px;
  height: calc(100vh - 99px);
  background: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(177, 177, 177, 0.3);
  z-index: 99;
  display: flex;
  flex-direction: column;
  .head {
    display: flex;
    justify-content: space-between;
    color: #000000;
    align-items: center;
    padding: 0 24px;
    border-bottom: 1px solid #d6d3d3;
  }
  .body {
    height: 100%;
    flex: 1;
    padding: 0 8px;
    overflow: hidden;
  }
  .record-list {
    overflow: auto;
    height: 100%;
    padding-bottom: 20px;
  }
  .record-item {
    display: flex;
    align-items: center;
    padding: 0 25px;
    height: 120px;
    color: #959595;
    border-bottom: 1px solid #d6d3d3;

    .detail {
      height: 100%;
      padding: 26px 0;
      display: flex;
      flex: 1;
      justify-content: space-between;
    }
    .nickname-action {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .nickname {
      color: #000;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 20px;
    }
    .action {
      width: 176px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
      font-size: 20px;
      line-height: 20px;
      font-weight: 400;
    }

    .avatar {
      width: 58px;
      height: 59px;
      margin-right: 22px;
    }
    .time {
      align-self: flex-start;
      height: 14px;
      line-height: 14px;
    }
  }
}
</style>
